// src/services/FulfillerService.ts
import * as http from './http';
import ApiService from './service';

// Create a FulfillerService class extending ApiService
export default class FulfillerService extends ApiService {
  // Function to load settings
  public async loadSettings() {
    const url = `${process.env.REACT_APP_API_DOMAIN
      }/fulfiller/index/website_id/${this.websiteId ? this.websiteId.toString() : '1'
      }/subsite_id/${this.subsiteId ? this.subsiteId.toString() : '1'}/languageID/${this.languageId ? this.languageId.toString() : '1'
      }/userToken/${this.token}`;
    const response = await http.get(url, this.token);
    return { data: response.data };
  }
  public async uploadImage(payload: any) {
    const url = `${process.env.REACT_APP_API_DOMAIN}/fulfiller/savephoto/`;
    const data = new FormData();
    data.append('photoSide', payload?.type);
    data.append('storePhoto', payload?.file);
    data.append('userToken', this.token ? this.token : '');
    data.append('website_id', this.websiteId ? this.websiteId.toString() : '1');
    data.append('subsite_id', this.subsiteId ? this.subsiteId.toString() : '1');
    data.append(
      'languageID',
      this.languageId ? this.languageId.toString() : '1'
    );
    return http.postAsFormData(url, data);
  }


  public async registerFulfill(payload: any, type: string) {
    const url = `${process.env.REACT_APP_API_DOMAIN}/fulfiller/${type}/`;
    const data = new FormData();
    data.append('userToken', this.token ? this.token : '');

    data.append('fulfillerToken', payload.fulfillerToken);
    data.append('storeStatus', payload.storeStatus);

    data.append('website_id', this.websiteId ? this.websiteId.toString() : '1');
    data.append('subsite_id', this.subsiteId ? this.subsiteId.toString() : '1');
    data.append(
      'languageID',
      this.languageId ? this.languageId.toString() : '1'
    );

    data.append('storeName', payload.storeName);
    data.append('storePhone', payload.storePhone);
    data.append('storeAddress', payload.storeAddress);
    data.append('storeArea', payload.storeArea);
    data.append('storeCity', payload.storeCity);
    if (payload?.storeFrontPhoto) {
      data.append('storeFrontPhoto', payload.storeFrontPhoto);
    }
    if (payload?.storeInnerPhoto) {
      data.append('storeInnerPhoto', payload.storeInnerPhoto);
    }
    data.append('smallVechicles', payload.smallVechicles);
    data.append('mediumVechicles', payload.mediumVechicles);
    data.append('largeVechicles', payload.largeVechicles);
    data.append('latitude', payload.latitude);
    data.append('longitude', payload.longitude);

    return http.postAsFormData(url, data);
  }

  public async getListFulFill(params: any, page:number): Promise<{ data: any }> {
    const url = `${process.env.REACT_APP_API_DOMAIN
      }/fulfiller/list/website_id/${this.websiteId ? this.websiteId.toString() : '1'
      }/subsite_id/${this.subsiteId ? this.subsiteId.toString() : '1'}/languageID/${this.languageId ? this.languageId.toString() : '1'
      }/userToken/${this.token}/page/${page ? page : ''}${params ? params : ''}`;
    const response = await http.get(url, this.token);
    return { data: response.data };
  }

  public async deleteFulFill(fulfillerToken: any) {
    const url = `${process.env.REACT_APP_API_DOMAIN}/fulfiller/delete/`;
    const data = new FormData();
    data.append('fulfillerToken', fulfillerToken);
    data.append('userToken', this.token ? this.token : '');
    data.append('website_id', this.websiteId ? this.websiteId.toString() : '1');
    data.append('subsite_id', this.subsiteId ? this.subsiteId.toString() : '1');
    data.append(
      'languageID',
      this.languageId ? this.languageId.toString() : '1'
    );
    return http.postAsFormData(url, data);
  }
  public async getFulFillDetail(fulfillerToken: any): Promise<{ data: any }> {
    const url = `${process.env.REACT_APP_API_DOMAIN
      }/fulfiller/details/website_id/${this.websiteId ? this.websiteId.toString() : '1'
      }/subsite_id/${this.subsiteId ? this.subsiteId.toString() : '1'}/languageID/${this.languageId ? this.languageId.toString() : '1'
      }/userToken/${this.token}/fulfillerToken/${fulfillerToken}`;
    const response = await http.get(url, this.token);
    return { data: response.data };
  }
  public async FulfillerFilderData(params?: any): Promise<{ data: any }> {
    const url = `${process.env.REACT_APP_API_DOMAIN
      }/fulfiller/orderfilter/website_id/${this.websiteId ? this.websiteId.toString() : '1'
      }/subsite_id/${this.subsiteId ? this.subsiteId.toString() : '1'}/languageID/${this.languageId ? this.languageId.toString() : '1'
      }/userToken/${this.token}`;
    const response = await http.get(url, this.token);
    return { data: response.data };
  }
  public async FulfillerOrderCount(params?: any): Promise<{ data: any }> {
    const url = `${process.env.REACT_APP_API_DOMAIN
      }/fulfiller/ordercount/website_id/${this.websiteId ? this.websiteId.toString() : '1'
      }/subsite_id/${this.subsiteId ? this.subsiteId.toString() : '1'}/languageID/${this.languageId ? this.languageId.toString() : '1'
      }/userToken/${this.token}${params ? params : ''}`;
    const response = await http.get(url, this.token);
    return { data: response.data };
  }

  public async getOrderListApi(params?: any): Promise<{ data: any }> {
    const url = `${process.env.REACT_APP_API_DOMAIN
      }/fulfiller/orderlist/website_id/${this.websiteId ? this.websiteId.toString() : '1'
      }/subsite_id/${this.subsiteId ? this.subsiteId.toString() : '1'}/languageID/${this.languageId ? this.languageId.toString() : '1'
      }/userToken/${this.token}${params ? params : ''}`;
    // /storeName/new+store/storePhone/2781234567890/storeArea/tri/storeCity/tri/status/Requested/storeStatus/Inactive/
    const response = await http.get(url, this.token);
    return { data: response.data };
  }
  public async statusChange(formData?: any, reason?:string) {
    console.log(formData)
    const url = `${process.env.REACT_APP_API_DOMAIN}/fulfiller/orderstatus/`;
    const data = new FormData();
    data.append('userToken', this.token ? this.token : '');
    data.append('website_id', this.websiteId ? this.websiteId.toString() : '1');
    data.append('subsite_id', this.subsiteId ? this.subsiteId.toString() : '1');
    data.append('languageID',this.languageId ? this.languageId.toString() : '1');

    data.append('fulfillerToken', formData?.itemData?.fulfillerToken ? formData?.itemData?.fulfillerToken : '');
    data.append('masterOrderID', formData?.itemData?.masterOrderID ? formData?.itemData?.masterOrderID : '');
    data.append('status', formData?.status ? formData?.status : '');
    data.append('reason', reason ? reason : '');
    data.append('shipmentDate', formData?.shipmentDate ? formData?.shipmentDate : '');
    data.append('deliveryDays', formData?.deliveryDays ? formData?.deliveryDays : '');
    


    return http.postAsFormData(url, data);
  }




}
